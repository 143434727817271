export const DEFAULT_PLATFORM_FEE = 0.1;

export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export const GOOGLE_ADS_ID = process.env.NEXT_PUBLIC_GOOGLE_ADS_ID;
export const WEBSITE_NAME = process.env.NEXT_PUBLIC_WEBSITE_NAME;
export const WEBSITE_URL = process.env.NEXT_PUBLIC_WEBSITE_URL;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const SOCKET_API_URL = process.env.NEXT_PUBLIC_SOCKET_URL;

export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;
export const STRIPE_LOGIN_URL = 'https://dashboard.stripe.com/login';

export const LANGUAGES = ['pt', 'en', 'es', 'others'] as const;
export const LANGUAGES_LABELS = {
  pt: 'Português',
  en: 'Inglês',
  es: 'Espanhol',
  others: 'Outros',
};
export const CURRENCIES = ['BRL'] as const;
export const CURRENCIES_LABELS = {
  BRL: 'Reais',
};

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const EMAIL_COOKIE_ID = 'email';

export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const FIREBASE_AUTHDOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN;
export const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
export const FIREBASE_FCM_VAPID = process.env.NEXT_PUBLIC_FIREBASE_FCM_VAPID;

export const VIDEO_SDK_TOKEN = process.env.NEXT_PUBLIC_VIDEOSDK_TOKEN;
export const VIDEO_SDK_API_KEY = process.env.NEXT_PUBLIC_VIDEOSDK_API_KEY;

export const LOGDNA_KEY = (process.env.NEXT_PUBLIC_LOGDNA_KEY || '').replace(
  /\s/g,
  '',
);
export const LOGDNA_NAME = process.env.NEXT_PUBLIC_LOGDNA_NAME;

export const ADMINS_CHAT_ID = 'admins';

export const DEFAULT_THUMBNAIL_URL = '/icons/user-placeholder.png';

export const VIDEO_ICE_SERVERS = [
  // { urls: 'stun:stun1.l.google.com:19302' },
  // { urls: 'stun:stun2.l.google.com:19302' },
  // { urls: 'stun:stun3.l.google.com:19302' },
  // { urls: 'stun:stun4.l.google.com:19302' },
  { urls: 'stun:global.stun.twilio.com:3478?transport=udp' },
];

export enum ROLES {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  CLIENT = 'CLIENT',
}

export const STRIPE_ON_BOARDING_RETURN_PATH = '/app/p';
export const STRIPE_ON_BOARDING_REFRESH_PATH = '/app/p';

export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
