import LogdnaLogger from 'logdna';
import { IS_PRODUCTION, LOGDNA_KEY, LOGDNA_NAME } from 'config/constants';

const logger = LOGDNA_KEY
  ? LogdnaLogger.createLogger(LOGDNA_KEY, {
      app: LOGDNA_NAME,
      index_meta: true,
    })
  : undefined;

export interface LogOptions {
  context?: string;
  showInProduction?: boolean;
  registerInCloud?: boolean;
}

export class Logger {
  public context?: string;

  constructor(context?: string) {
    this.context = context;
  }

  getTimestamp(): string {
    return new Date().toLocaleString('pt-br', {
      timeZone: 'America/Sao_Paulo',
    });
  }

  getPrefix({ context = this.context }: LogOptions = {}): string {
    let prefix = '';

    if (context) {
      prefix += `[${context}]`;
    }

    return prefix;
  }

  needRegisterInCloud(options?: LogOptions): boolean {
    return !!logger && !!options?.registerInCloud;
  }

  needShowInConsole(options?: LogOptions): boolean {
    return !IS_PRODUCTION || !!options?.showInProduction;
  }

  private errorToObject(error: Error) {
    return {
      stack: error.stack,
      message: error.message,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getMeta(data?: Error | any) {
    if (!data) return {};
    if (data instanceof Error) {
      return this.errorToObject(data);
    }

    return Object.entries(data).reduce((oldValue, [key, value]) => {
      const newValue = Object.assign({}, oldValue);

      if (value instanceof Error) {
        newValue[key] = JSON.stringify(this.errorToObject(value));
      } else {
        newValue[key] = JSON.stringify(value);
      }

      return newValue;
    }, {});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message: string, data?: any, opt: LogOptions = {}): void {
    try {
      const options: LogOptions = {
        registerInCloud: true,
        ...opt,
      };
      if (this.needRegisterInCloud(options)) {
        if (logger)
          logger.error(`${this.getPrefix(options)} ${message}`, {
            meta: this.getMeta(data),
          });
      }

      if (this.needShowInConsole(options)) {
        console.error(
          `${this.getTimestamp()} ${this.getPrefix(options)} ${message}`,
          data,
        );
      }
    } catch (err) {
      console.error(`Erro ao enviar error para serviço`, err);
    }
  }

  log(message: string, options?: LogOptions): void {
    try {
      if (this.needRegisterInCloud(options)) {
        if (logger) logger.log(`${this.getPrefix(options)} ${message}`);
      }
      if (this.needShowInConsole(options)) {
        console.log(
          `${this.getTimestamp()} ${this.getPrefix(options)} ${message}`,
        );
      }
    } catch (err) {
      console.error(`Erro ao enviar log para serviço`, err);
    }
  }

  warn(message: string, data?: any, opt: LogOptions = {}): void {
    try {
      const options: LogOptions = {
        registerInCloud: true,
        ...opt,
      };
      if (this.needRegisterInCloud(options)) {
        if (logger)
          logger.warn(`${this.getPrefix(options)} ${message}`, {
            meta: this.getMeta(data),
          });
      }
      if (this.needShowInConsole(options)) {
        console.warn(
          `${this.getTimestamp()} ${this.getPrefix(options)} ${message}`,
        );
      }
    } catch (err) {
      console.error(`Erro ao enviar warn para serviço`, err);
    }
  }

  debug(message: string, options?: LogOptions): void {
    try {
      if (this.needRegisterInCloud(options)) {
        if (logger) logger.debug(`${this.getPrefix(options)} ${message}`);
      }

      if (this.needShowInConsole(options)) {
        console.debug(
          `${this.getTimestamp()} ${this.getPrefix(options)} ${message}`,
        );
      }
    } catch (err) {
      console.error(`Erro ao enviar debug para serviço`, err);
    }
  }
}
